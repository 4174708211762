import { ChangeDetectorRef, Component, HostListener, Injector, Input} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PagedListingComponentBase, PagedRequestDto } from 'shared/paged-listing-component-base';
import { VagaDto, VagaServiceProxy, CandidatoDto, CandidatoVagaServiceProxy, CandidatoVagaDto, EmpresaServiceProxy, EnumServiceProxy, EnumDtoListResultDto, EnumDto, VagasVitrineDto, MicrorregiaoServiceProxy, MicrorregiaoDto, VagaSimplificadaDto } from '@shared/service-proxies/service-proxies';
import { Filtro } from 'shared/components/filtro/models/filtro.model';
import { Observable, Subscription } from 'rxjs';
import { FiltroService } from '@shared/components/filtro/service/filtro.service';
import { CandidatoService } from 'candidato/service/candidato.service';
import { TokenService } from 'abp-ng2-module';
import { FiltroDialogComponent } from './modal-filtro/modal-filtro.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SelectItem } from './filtro/select-item.interface';
import { EnumNames } from '../../../enums/enum-names';
import { LayoutStoreService } from '@shared/layout/layout-store.service';

class PagedVagaRequestDto extends PagedRequestDto {
  keyword: string;
}

@Component({
  selector: "vitrine-vagas",
  templateUrl: './vitrine.component.html',
  styleUrls: ["./vitrine.component.scss"],
  animations: [appModuleAnimation()]
})
export class VitrineComponent extends PagedListingComponentBase<VagaSimplificadaDto> {
  @Input() parentContainer: HTMLElement;

  vagasGeral: VagaSimplificadaDto[] = [];
  vagasMicrorregiao: VagaSimplificadaDto[] = [];
  empresas: SelectItem[] = [];
  loadEmpresas: boolean = false;
  inscricoes: CandidatoVagaDto[] = [];
  public filtro: Filtro;
  private filtroSub: Subscription = new Subscription();
  candidatoDto: CandidatoDto;
  microrregiaoLista: MicrorregiaoDto[];
  private candidatoSub: Subscription = new Subscription();
  standalone: true;
  show = true;
  keyword = '';
  private microrregiaoId: string;
  protected previousFilters: any = {};
  @Input() vagaBusca: string;
  placeholderText = "Digite sua busca...";
  loadCompleto = false;
  isModalOpen = false;
  isFiltroActive = false;
  loadMicrorregiao = false;
  possuiaVagasAnteriorAoFiltro = false;
  textobusca: string = '';
  isSmallScreen: boolean;
  max = 0;
  listaModeloTrabalho: EnumDto[] = [];
  private sidebarSubscription: Subscription;

  constructor(
    injector: Injector,
    private _vagaService: VagaServiceProxy,
    private _candidatoVagaService: CandidatoVagaServiceProxy,
    private _microrregiaoService: MicrorregiaoServiceProxy,
    private cdr: ChangeDetectorRef,
    public filtroService: FiltroService,
    public candidatoService: CandidatoService,
    public _empresaService: EmpresaServiceProxy,
    private _tokenService: TokenService,
    private _modalService: BsModalService,
    private _enumServiceProxy: EnumServiceProxy,
    private _layoutStore: LayoutStoreService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize.bind(this));

    this.sidebarSubscription = this._layoutStore.sidebarExpanded.subscribe(expanded => {
      this.updateScreenSize();
    });

    if (!this.isModalOpen && !this.isFiltroActive) {
      this.filtro = this.filtroService.getFiltro();
      this.filtroSub = this.filtroService.getFiltroUpdateListener()
        .subscribe((filtro: Filtro) => {
          this.filtro = filtro;
          this.refresh();
        });
      this.isFiltroActive = true;
    }

    if (this.isLoggedIn()) {
      this.initCandidato();
      this.getEmpresas();
      this.getListModeloTrabalho();
      this.getSalarioMaisAlto();
      this.getMicrorregioes();
      this.cdr.markForCheck();
      if (this.candidatoDto) {
        this.getInscricoes(this.candidatoDto.id);
      }
    } else {
      this.refresh();
      this.getEmpresas();
      this.getListModeloTrabalho();
      this.getSalarioMaisAlto();
      this.cdr.markForCheck();
    }

    this.filtroService.getTextoBusca().subscribe((texto: string) => {
      this.textobusca = texto;
    });
  }

  updateSearchText(): void {
    // Caso precise sincronizar com o serviço quando o usuário altera diretamente no campo
    this.filtroService.setTextoBusca(this.textobusca);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateScreenSize();
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.updateScreenSize.bind(this));
    if (this.sidebarSubscription) {
      this.sidebarSubscription.unsubscribe();
    }
  }

  isLoggedIn(): boolean {
    const token = this._tokenService.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  private isTokenExpired(token: string): boolean {
    const tokenPayload = this.decodeToken(token);

    if (tokenPayload && tokenPayload.exp) {
      const expirationDateSeconds = tokenPayload.exp;

      const expirationDateMilliseconds = expirationDateSeconds * 1000;

      const currentTimestampMilliseconds = new Date().getTime();

      return expirationDateMilliseconds < currentTimestampMilliseconds;
    }

    return true;
  }

  private decodeToken(token: string): any {
    try {
      const tokenPayloadBase64 = token.split('.')[1];
      const tokenPayloadJson = atob(tokenPayloadBase64);
      return JSON.parse(tokenPayloadJson);
    } catch (error) {
      return null;
    }
  }

  async initCandidato(): Promise<void> {
    this.candidatoDto = this.candidatoService.getCandidato();
    if (this.candidatoDto) {
      this.updateFiltroMicrorregiao(this.candidatoDto);
      this.cdr.markForCheck();
    }

    this.candidatoSub = this.candidatoService.getCandidatoUpdateListener()
      .subscribe((candidato: CandidatoDto) => {
        this.candidatoDto = candidato;
        this.updateFiltroMicrorregiao(candidato);
        this.getInscricoes(candidato.id);
        this.cdr.markForCheck();
      });
  }

  updateFiltroMicrorregiao(candidato: CandidatoDto) {
    const microrregiaoId = candidato.curriculoDadoPessoal?.endereco?.microrregiaoId;
    if (this.filtro) {
      this.filtro.microrregiaoId = microrregiaoId;
    } else {
      this.filtro = { microrregiaoId };
    }
    this.filtroService.setFiltro(this.filtro);
  }

  protected list(
    request: PagedVagaRequestDto,
    pageNumber: number,
    finishedCallback: Function
  ): void {
    const currentFilters = {
      keyword: this.vagaBusca == undefined ? this.filtro?.textoBusca : this.vagaBusca,
      empresaId: this.filtro?.empresaId,
      cargosId: this.filtro?.cargosId,
      competenciasId: this.filtro?.competenciasId,
      encerrada: this.filtro?.encerrada,
      naoIniciada: this.filtro?.naoIniciada,
      dataInicio: this.filtro?.dataInicio,
      dataTermino: this.filtro?.dataTermino,
      estado: this.filtro?.estado,
      cidade: this.filtro?.cidade,
      modeloTrabalho: this.filtro?.modeloTrabalho,
      regimeTrabalho: this.filtro?.regimeTrabalho,
      salario: this.filtro?.salario,
      ordenacao: this.filtro?.ordenacao,
      nomeEmpresa: this.filtro?.nomeEmpresa,
      cnpjEmpresa: this.filtro?.cnpjEmpresa,
      pcd: this.filtro?.pcd,
      microrregiaoId: this.filtro?.microrregiaoId
    };
    this.vagaBusca = undefined;
    let filtersChanged = false;
    for (const key in currentFilters) {
      if (currentFilters[key] !== this.previousFilters[key]) {
        filtersChanged = true;
        break;
      }
    }

    if (filtersChanged) {
      pageNumber = 1;
      request.skipCount = 0;
      this.previousFilters = { ...currentFilters };
    } else {
      request.skipCount = (pageNumber - 1) * request.maxResultCount;
    }

    request.keyword = currentFilters.keyword;

    abp.ui.setBusy();

    this._vagaService
      .getVagasVitrine(
        request.keyword,
        this.filtro?.nomeEmpresa,
        this.filtro?.pcd,
        this.filtro?.estado,
        this.filtro?.cidade,
        this.filtro?.modeloTrabalho,
        this.filtro?.salario,
        this.filtro?.cargosId,
        this.filtro?.microrregiaoId,
        request.skipCount,
        request.maxResultCount
      )
      .pipe(
        finalize(() => {
          finishedCallback();
          abp.ui.clearBusy();
        })
      )
      .subscribe((result: VagasVitrineDto) => {
        this.vagasGeral = result.vagasGeral;
        this.vagasMicrorregiao = result.vagasMicrorregiao;
        if (this.vagasMicrorregiao && this.vagasMicrorregiao.length > 0)
          this.possuiaVagasAnteriorAoFiltro = true;
        this.totalItems = result.total;
        this.pageNumber = pageNumber
        this.totalPages = Math.ceil(result.total / this.pageSize);
        this.loadCompleto = true;
        this.cdr.markForCheck();
      });
  }

  protected delete(vaga: VagaSimplificadaDto): void { }

  getMicrorregioes() {
    abp.ui.setBusy();
    this._microrregiaoService.getAll().pipe(
      finalize(() => {
        abp.ui.clearBusy();
      })
    ).subscribe((microrregioes) => {
      this.microrregiaoLista = microrregioes;
      this.loadMicrorregiao = true;
    })
  }

  getInscricoes(id) {
    abp.ui.setBusy();
    this._candidatoVagaService.getInscricoesCanditado(id).pipe(
      finalize(() => {
        abp.ui.clearBusy();
      })
    ).subscribe((inscricoes) => {
      this.inscricoes = inscricoes;
    })
  }

  isInscrito(vaga: VagaDto) {
    if (this.inscricoes.length > 0) {
      return this.inscricoes.some(inscricao =>
        inscricao.vagaId === vaga.id && inscricao.candidatoId === this.candidatoDto.id
      );
    }
    else {
      return false;
    }
  }

  abrirFiltro(): void {
    if (this.loadEmpresas && this.max > 0 && this.listaModeloTrabalho.length > 0) {
      this.isModalOpen = true;
      this._modalService.show(FiltroDialogComponent, {
        class: 'modal-fullscreen',
        initialState: {
          empresas: this.empresas,
          isLoggedIn: this.isLoggedIn(),
          listaModeloTrabalho: this.listaModeloTrabalho
        }
      }).onHide.subscribe(() => {
        this.isModalOpen = false;
      });
    }
  }

  limparFiltros(){
    this.textobusca = null;
    this.filtroService.setTextoBusca(this.textobusca);
    this.updateSearchText();
  }

  updateScreenSize() {
    if (this.parentContainer) {
      const { width } = this.parentContainer.getBoundingClientRect();
      this.isSmallScreen = width <= 1000;

      const vagasContainer = document.getElementById('vagas-container');
      if (vagasContainer) {
        if (width <= 1000) {
          vagasContainer.style.width = '100%';
          vagasContainer.style.flex = '0 0 100%';
          vagasContainer.style.maxWidth = '100%';
        } else {
          vagasContainer.style.width = '';
          vagasContainer.style.flex = '';
          vagasContainer.style.maxWidth = '';
          vagasContainer.style.backgroundColor = '';
        }
        if (width <= 380) {
          this.placeholderText = "Digite...";
        } else {
          this.placeholderText = "Digite sua busca...";
        }
      }
    } else {
      const screenWidth = window.innerWidth;
      this.isSmallScreen = screenWidth <= 1000;
    }

    this.cdr.markForCheck();
  }

  checkScreenSize(): boolean {
    return this.isSmallScreen;
  }

  getEmpresas() {
    abp.ui.setBusy();
    this._empresaService.getEmpresasLista().pipe(
      finalize(() => {
        abp.ui.clearBusy();
      })
    ).subscribe((empresa) => {
      if (empresa.length > 0) {
        this.empresas = empresa.map((x: any, index: number) => ({
          item_id: x.id,
          item_text: x.nome,
        }));
      }
      this.loadEmpresas = true;
    })
  }

  getSalarioMaisAlto() {
    abp.ui.setBusy();
    this._vagaService.getSalarioMaisAlto()
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe(x => {
        this.max = x;
      });
  };

  private load<T>(serviceCall: () => Observable<T>, setter: (data: T) => void): void {
    abp.ui.setBusy();
    serviceCall()
      .pipe(finalize(() => abp.ui.clearBusy()))
      .subscribe(setter);
  }

  private getListByEnumName(enumName: string, assignTo: (items: any[]) => void): Subscription {
    return this._enumServiceProxy
      .getByEnumName(enumName)
      .subscribe((result: EnumDtoListResultDto) => {
        assignTo(result.items);
      });
  }

  private getListModeloTrabalho(): Subscription {
    return this.getListByEnumName(EnumNames.MODELO_TRABALHO_ENUM, (items) => {
      this.listaModeloTrabalho = items;
    });
  }

  checkGeralVagas() {
    return this.isLoggedIn() && this.hasVagas(this.vagasGeral) && this.loadCompleto;
  }

  checkNaoHaVagasDisponiveisFiltradas() {
    return !this.hasVagas(this.vagasGeral) && !this.hasVagas(this.vagasMicrorregiao) && this.hasActiveFilters() && ((this.loadCompleto || (!this.loadCompleto && (!this.isLoggedIn()))) && (!this.checkNaoPossuiVagasAbertasRegiaoRequisitosFiltrados() || !this.isLoggedIn()) || (this.isLoggedIn() && this.candidatoIsGeral()));
  }

  checkVagaSuaRegiao() {
    return this.isLoggedIn() && this.hasVagas(this.vagasMicrorregiao) && this.loadCompleto && this.loadMicrorregiao && !this.candidatoIsGeral();
  }

  checkNaoPossuiVagasAbertasRegiao() {
    return this.isLoggedIn() && !this.hasVagas(this.vagasMicrorregiao) && !this.possuiaVagasAnteriorAoFiltro && this.loadCompleto && this.loadMicrorregiao && !this.candidatoIsGeral();
  }

  checkNaoPossuiVagasAbertasRegiaoRequisitosFiltrados() {
    return this.isLoggedIn() && this.possuiaVagasAnteriorAoFiltro && this.hasActiveFilters() && !this.hasVagas(this.vagasMicrorregiao) && this.loadCompleto && !this.candidatoIsGeral();
  }

  checkVagasOutrasRegioes() {
    return !this.checkVagaSuaRegiao() && this.checkGeralVagas() && !this.candidatoIsGeral();
  }

  checkVagasRegiaoUsuarioEOutras() {
    return this.checkVagaSuaRegiao() && this.checkGeralVagas() && !this.candidatoIsGeral();
  }

  hasVagas(vagas: any[]) {
    return vagas && vagas.length > 0;
  }

  candidatoIsGeral(): boolean {
    if (this.loadMicrorregiao) {
      const microrregiaoGeral = this.microrregiaoLista.find((microrregiao) => microrregiao.nome === "Geral");
      return (this.candidatoDto.curriculoDadoPessoal && this.candidatoDto.curriculoDadoPessoal.endereco && this.candidatoDto.curriculoDadoPessoal.endereco.microrregiaoId === microrregiaoGeral?.id) || !this.candidatoDto.curriculoDadoPessoal;
    }
  }

  hasActiveFilters(): boolean {
    if (this.filtro == null) {
      return false;
    }

    return (
      (this.filtro.pcd !== undefined && this.filtro.pcd !== null) ||
      (this.filtro.salario !== undefined && this.filtro.salario > 0) ||
      Boolean(this.filtro.estado) ||
      Boolean(this.filtro.cidade) ||
      (Array.isArray(this.filtro.cargosId) && this.filtro.cargosId.length > 0) ||
      Boolean(this.filtro.empresaId) ||
      Boolean(this.filtro.nomeEmpresa) ||
      (this.filtro.modeloTrabalho !== undefined && this.filtro.modeloTrabalho !== null)
    );
  }

}
