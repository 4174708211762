import { Component, Injector } from "@angular/core";
import { finalize } from "rxjs/operators";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/app-component-base";
import {
  CandidatoDto,
  CandidatoServiceProxy,
  CreateCandidatoVagaDto,
  VagaDto,
  VagaServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";
import { CandidatoService } from "candidato/service/candidato.service";
import { PixelService } from "ngx-multi-pixel";
import { TokenService } from 'abp-ng2-module';
import { MatDialog } from "@angular/material/dialog";
import { Pages } from "@shared/constant/peges.model";
import { GoogleAnalyticsAcoes } from "@shared/constant/google-analytics-acoes.model";
import { GoogleAnalyticsService } from "@shared/services/google-analytics.service";
import { CandidatarVagaEspecialComponent } from "../candidatar-vaga-especial/candidatar-vaga-especial.component";
import { AppSessionService } from "@shared/session/app-session.service";

@Component({
  selector: 'app-detalhes-vaga-especial',
  templateUrl: './detalhes-vaga-especial.component.html',
  styleUrls: ['./detalhes-vaga-especial.component.scss'],
  animations: [appModuleAnimation()],
})
export class DetalhesVagaEspecialComponent extends AppComponentBase {
  vaga: VagaDto = new VagaDto();
  candidato: CandidatoDto = new CandidatoDto();
  createCandidatoVaga: CreateCandidatoVagaDto = new CreateCandidatoVagaDto();
  telefoneFormatado: string;
  rota: string = '/vagas';

  constructor(
    injector: Injector,
    private _route: ActivatedRoute,
    private _routeNavigator: Router,
    private _vagaService: VagaServiceProxy,
    private _candidatoService: CandidatoServiceProxy,
    private serviceCanditadoOptions: CandidatoService,
    public bsModalRef: BsModalRef,
    private pixel: PixelService,
    private _tokenService: TokenService,
    private dialog: MatDialog,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _sessionService: AppSessionService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.onConsent();
    if (this.isLoggedIn()) {
      this.candidato = this.serviceCanditadoOptions.getCandidato();
      if (this.candidato == undefined) {
        if (this.appSession.getRoles().length >= 1) {
          if (this.verificaCandidato(this.appSession.user.roleNames)) {
            this.getCandidato();
          }
        }
      }
      if (this._sessionService.user) {
        this.rota = '/candidato/home';
      }
    }
    this._route.params.subscribe(params => {
      abp.ui.setBusy();
      this._vagaService.get(params['id'])
        .pipe(
          finalize(() => {
            abp.ui.clearBusy();
          })
        )
        .subscribe(x => {
          this.vaga = x;
          this.setupShareButtons();
        });
    });

    this.googleAnalyticsEventSend();
  }

  googleAnalyticsEventSend() {
    this._googleAnalyticsService.sendEmiter(GoogleAnalyticsAcoes.acessoVagaDetalhe, {
      event_label: Pages.detalheVaga
    });
  }

  onConsent(): void {
    this.pixel.initialize();
    this.pixel.track("ViewContent", {
      content_name: "Detalhes vaga - Candidato"
    });
  }

  verificaCandidato(array: Array<string>): boolean {
    return array.some(role => role.includes("Candidato"));
  }

  copyLink(event: Event) {
    const url = this.getCurrentUrl();
    navigator.clipboard.writeText(url).then(() => {
      abp.message.info('Link copiado para a área de transferência!');
    }).catch(err => {
      abp.message.warn('Erro ao copiar o link: ' + err);
    });
  }

  setupShareButtons() {
    const url = this.getCurrentUrl();
    const encodedUrl = encodeURIComponent(url);
    const whatsappShareButtons = document.querySelectorAll('.whatsapp-share') as NodeListOf<HTMLAnchorElement>;

    whatsappShareButtons.forEach(whatsappShare => {
      whatsappShare.href = `https://api.whatsapp.com/send?text=${encodeURIComponent('Confira esta vaga: ')}${encodedUrl}`;
      whatsappShare.addEventListener('click', (event) => {
        event.preventDefault();
        window.open(whatsappShare.href, '_blank');
      });
    });
  }

  getFormattedDate(date: moment.Moment): string {
    if (!date) {
      return '';
    } else
      return date.format('D [de] MMMM [de] YYYY');
  }

  goBack() {
    this._routeNavigator.navigate([this.rota]);
  }

  getCurrentUrl(): string {
    return `${window.location.origin}/vagas/detalhes-vaga-especial/${this.vaga.id}`;
  }

  candidatar() {
    this.dialog.open(CandidatarVagaEspecialComponent, {
      data: {
        vaga: this.vaga,
        candidato: this.candidato,
      },
    });
  }

  getCandidato() {
    abp.ui.setBusy();
    this._candidatoService.getAbp()
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      ).subscribe(x => {
        this.candidato = x;
        this.serviceCanditadoOptions.setCandidato(this.candidato);
        this.formatarTelefone(this.candidato.telefone);
      });
  }

  formatarTelefone(telefone: string) {
    const telefoneLimpo = telefone.replace(/\D/g, '');
    this.telefoneFormatado = `(${telefoneLimpo.slice(0, 2)}) ${telefoneLimpo.slice(2, 3)} ${telefoneLimpo.slice(3, 7)}-${telefoneLimpo.slice(7)}`;
  }

  isLoggedIn(): boolean {
    const token = this._tokenService.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  private isTokenExpired(token: string): boolean {
    const tokenPayload = this.decodeToken(token);

    if (tokenPayload && tokenPayload.exp) {
      const expirationDateSeconds = tokenPayload.exp;

      const expirationDateMilliseconds = expirationDateSeconds * 1000;

      const currentTimestampMilliseconds = new Date().getTime();

      return expirationDateMilliseconds < currentTimestampMilliseconds;
    }

    return true;
  }

  private decodeToken(token: string): any {
    try {
      const tokenPayloadBase64 = token.split('.')[1];
      const tokenPayloadJson = atob(tokenPayloadBase64);
      return JSON.parse(tokenPayloadJson);
    } catch (error) {
      return null;
    }
  }

}
