import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';
import { ConsultaCepService } from "./services/consulta-cep.service";
import { ConsultaEstadosService } from "./services/consulta-estados.service";
import { SanitizeHtmlPipe } from "./pipes/sanitizeHtml.pipe";
import { FilterableSelectComponent } from "./components/filterable-select/filterable-select.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { JsonDataService } from "./services/json-data.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";

import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { TermsOfUseComponent } from "./pages/terms-of-use/terms-of-use.component";

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpPaginationCustomControlsComponent } from './components/pagination-custom/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';
import { LayoutStoreService } from './layout/layout-store.service';
import { AcceptTermoDialogComponent } from "./components/accept-termo-dialog/accept-termo-dialog.component";

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';

import { BuscarCnpjService } from './services/buscar-cnpj.service';
import { CharactersService } from './services/characters.service';
import { VagaService } from './services/vaga.service';
import { VerificarRotaService } from './services/verificar-rota.service';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { OnlyAlphanumericDirective } from './directives/only-alphanumeric.directive';
import { LoginGuard } from './auth/login-guard';

import { TimeLineComponent } from './components/candidato/experiencia/timeline.component';

//vitrine
import { VitrineComponent } from './components/vitrine/vitrine.component';
import { FiltroVitrineComponent } from './components/vitrine/filtro/filtro-vitrine.component';
import { CardCandidatoVagaComponent } from './components/vitrine/card-vaga/card-candidato-vaga.component';
import { DetalhesVagaComponent } from './components/vitrine/card-vaga/detalhes/detalhes-vaga.component';
import { ConfirmationDialogComponent } from './components/vitrine/card-vaga/detalhes/candidatar/candidatar-vaga.component';
import { CardOrdenacaoComponent } from './components/vitrine/card-ordenacao/card-ordenacao-vaga.component';

// mat
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';

//others
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AlertEmailDialogComponent } from './components/alert-email-dialog/alert-email-dialog.component';
import { AlertCardComponent } from './components/alert-card/alert-card.component';
import { FiltroDialogComponent } from './components/vitrine/modal-filtro/modal-filtro.dialog.component';
import { InputSelectTextComponent } from './components/input-select-text/input-select-text.component';
import { ToggleCustomComponent } from './components/vitrine/filtro/toggle-custom/toggle-custom.component';
import { SliderTickCustomComponent } from './components/vitrine/filtro/slider-tick-custom/slider-tick-custom.component';
import { SelectBoxCustomComponent } from './components/vitrine/filtro/select-box-custom/select-box-custom.component';

import { EnderecoFormComponent } from './components/endereco-form/endereco-form.component';
import { CardVagaEspecialComponent } from './components/vitrine/card-vaga-especial/card-vaga-especial.component';
import { DetalhesVagaEspecialComponent } from './components/vitrine/card-vaga-especial/detalhes-vaga-especial/detalhes-vaga-especial.component';
import { CandidatarVagaEspecialComponent } from './components/vitrine/card-vaga-especial/candidatar-vaga-especial/candidatar-vaga-especial.component';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        NgxCurrencyModule,
        NgxMaskModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        //mat
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatMenuModule,
        MatSliderModule,
        MatCheckboxModule,
        MatCardModule,
        MatTabsModule,
        MatRadioModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatSidenavModule,
        MatTooltipModule,
        NgMultiSelectDropDownModule.forRoot(),
        BsDropdownModule,
        NgbModule
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpPaginationCustomControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        OnlyNumberDirective,
        OnlyAlphanumericDirective,
        TermsOfUseComponent,
        PrivacyPolicyComponent,
        AcceptTermoDialogComponent,
        SanitizeHtmlPipe,
        FilterableSelectComponent,
        TimeLineComponent,
        VitrineComponent,
        FiltroVitrineComponent,
        CardCandidatoVagaComponent,
        DetalhesVagaComponent,
        ConfirmationDialogComponent,
        CardOrdenacaoComponent,
        AlertEmailDialogComponent,
        AlertCardComponent,
        EnderecoFormComponent,
        FiltroDialogComponent,
        InputSelectTextComponent,
        ToggleCustomComponent,
        SliderTickCustomComponent,
        SelectBoxCustomComponent,
        CardVagaEspecialComponent,
        DetalhesVagaEspecialComponent,
        CandidatarVagaEspecialComponent
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpPaginationCustomControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        OnlyNumberDirective,
        OnlyAlphanumericDirective,
        TermsOfUseComponent,
        PrivacyPolicyComponent,
        SanitizeHtmlPipe,
        FilterableSelectComponent,
        TimeLineComponent,
        VitrineComponent,
        FiltroVitrineComponent,
        CardCandidatoVagaComponent,
        DetalhesVagaComponent,
        ConfirmationDialogComponent,
        CardOrdenacaoComponent,
        AlertEmailDialogComponent,
        AlertCardComponent,
        EnderecoFormComponent,
        FiltroDialogComponent,
        InputSelectTextComponent,
        ToggleCustomComponent,
        SliderTickCustomComponent,
        SelectBoxCustomComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                LayoutStoreService,
                BuscarCnpjService,
                VagaService,
                CharactersService,
                LoginGuard,
                ConsultaCepService,
                ConsultaEstadosService,
                JsonDataService,
                VerificarRotaService,
                GoogleAnalyticsService
            ]
        };
    }
}
