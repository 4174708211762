import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRouteGuard } from '@shared/auth/auth-route-guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('initial/initial.module').then(m => m.InitialModule),
        data: { preload: true }
    },
    {
        path: 'empresa',
        loadChildren: () => import('empresa/empresa.module').then(m => m.EmpresaModule),
        canLoad: [AppRouteGuard],
        data: { preload: true, role: 'Empresa' }
    },
    {
        path: 'empresa-especial',
        loadChildren: () => import('empresa-especial/empresa-especial.module').then(m => m.EmpresaEspecialModule),
        canLoad: [AppRouteGuard],
        data: { preload: true, role: 'Empresa Especial' }
    },
    {
        path: 'candidato',
        loadChildren: () => import('candidato/candidato.module').then(m => m.CandidatoModule),
        canLoad: [AppRouteGuard],
        data: { preload: true, role: 'Candidato' }
    },
    {
        path: 'administrativo',
        loadChildren: () => import('administrativo/administrativo.module').then(m => m.AdministrativoModule), // Lazy load account module
        data: { preload: true, role: 'Admin'}
    },
    {
        path: 'account',
        loadChildren: () => import('account/account.module').then(m => m.AccountModule), // Lazy load account module
        data: { preload: true }
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
    })],
    exports: [RouterModule],
    providers: []
})
export class RootRoutingModule { }
