import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CharactersService {

  getMaxCharacters(): number {
    const screenWidth = window.innerWidth;

    const breakpoints = [
      { width: 3000, characters: 300 },
      { width: 2100, characters: 250 },
      { width: 1900, characters: 250 },
      { width: 1700, characters: 200 },
      { width: 1400, characters: 170 },
      { width: 1200, characters: 160 },
      { width: 1150, characters: 150 },
      { width: 1000, characters: 100 },
      { width: 768, characters: 150 },
      { width: 700, characters: 130 },
      { width: 628, characters: 220 },
      { width: 530, characters: 190 },
      { width: 430, characters: 160 },
    ];

    for (const breakpoint of breakpoints) {
      if (screenWidth > breakpoint.width) {
        return breakpoint.characters;
      }
    }
    return 130;
  }
}
