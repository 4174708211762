import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppComponentBase } from "@shared/app-component-base";
import {
  CandidatoDto,
  CandidatoServiceProxy,
  CandidatoVagaServiceProxy,
  CreateCandidatoVagaDto,
  VagaDto,
} from "@shared/service-proxies/service-proxies";
import { TokenService } from "abp-ng2-module";
import { CandidatoService } from "candidato/service/candidato.service";
import { LoginOrCreateSelectionDialogComponent } from "initial/login-or-create-dialog/login-or-create-selection-dialog.component";
import { OnboardingModalComponent } from "initial/para-candidatos/onboarding-modal/onboarding-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
  selector: 'app-candidatar-vaga-especial',
  templateUrl: './candidatar-vaga-especial.component.html',
  styleUrls: ['./candidatar-vaga-especial.component.scss']
})
export class CandidatarVagaEspecialComponent extends AppComponentBase implements OnInit {

  private candidatoSub: Subscription = new Subscription();
  createCandidatoVaga: CreateCandidatoVagaDto = new CreateCandidatoVagaDto();
  isInscrito: boolean = false;
  candidato: CandidatoDto;

  constructor(
    public dialogRef: MatDialogRef<CandidatarVagaEspecialComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { vaga: VagaDto },
    private _candidatoVagaService: CandidatoVagaServiceProxy,
    private router: Router,
    private _candidatoService: CandidatoServiceProxy,
    private serviceCanditadoOptions: CandidatoService,
    private _modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private _tokenService: TokenService,
    private _router: Router,
    injector: Injector
  ) {
    super(injector);
  }


  ngOnInit(): void {
    this.initCandidato();
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.verifyLoggedIn();
  }

  initCandidato(): void {
    this.candidato = this.serviceCanditadoOptions.getCandidato();
    this.candidatoSub = this.serviceCanditadoOptions.getCandidatoUpdateListener()
      .subscribe((candidato: CandidatoDto) => {
        this.candidato = candidato;
      });
  }

  realizarCandidatura() {
    if (!this.data.vaga.encerrada) {
      if (this.isCandidatoValido(this.candidato)) {
        abp.ui.setBusy();
        this.createCandidatoVaga.candidatoId = this.candidato.id;
        this.createCandidatoVaga.vagaId = this.data.vaga.id;

        this._candidatoVagaService
          .createCandidaturaVagaEspecial(this.createCandidatoVaga)
          .pipe(
            finalize(() => abp.ui.clearBusy())
          )
          .subscribe(
            (response) => {
              window.open(this.data.vaga.urlVaga, '_blank');
              this.onCandidaturaSuccess(response);
            },
          );
      } else {
        if (!this.candidato.onboardingCandidato)
          this.abrirOnboarding();
        else
          this.handleCandidaturaInvalida();
      }
    } else {
      abp.message.error('Esta vaga está encerrada ou inativa!');
      return
    }

  }

  verificaCandidato(array: Array<string>): boolean {
    return array.some(role => role.includes("Candidato"));
  }

  private isCandidatoValido(candidato: CandidatoDto): boolean {
    return (
      !!candidato.curriculoDadoPessoal &&
      !!candidato.curriculoDadoPessoal.endereco &&
      !!candidato.curriculoDadoPessoal.corRacaString &&
      !!this.candidato.onboardingCandidato &&
      candidato.rendaBrutaFamiliarEnum > 8 &&
      this.hasValidAttachmentsOrExperiences(candidato)
    );
  }

  private hasValidAttachmentsOrExperiences(candidato: any): boolean {
    return (
      !!candidato.curriculoDadoPessoalAnexo?.arquivoUrl?.length ||
      !!candidato.curriculosExperiencias?.length ||
      !!candidato.candidatosFormacoesAcademicas?.length ||
      !!candidato.candidatosIdiomas?.length ||
      !!candidato.curriculosCompetencias?.length
    );
  }

  private onCandidaturaSuccess(response: any) {
    this.candidato.candidatosVagas.push(response);
    this.serviceCanditadoOptions.setCandidato(this.candidato);
  }

  private handleCandidaturaInvalida() {
    this._candidatoService
      .tentativaCandidatarVaga(this.data.vaga.id)
      .pipe(finalize(() => abp.ui.clearBusy()))
      .subscribe((candidato) => {
        this.candidato = candidato;
        this.serviceCanditadoOptions.setCandidato(candidato);
       abp.notify.warn('Antes de se candidatar à vaga, por favor, preencha seu currículo. Estamos ansiosos para saber mais sobre você!');
        this.navigateToAppropriatePage(candidato);
      });
  }

  private navigateToAppropriatePage(candidato: CandidatoDto) {
    const route =
      candidato.rendaBrutaFamiliarEnum <= 8
        ? '/candidato/perfil'
        : '/candidato/meu-curriculo';
    this.router.navigate([route]);
  }

  private openLoginOrCreateSelectionModal(): void {
    let loginOrCreateSelectionDialog: BsModalRef;
    loginOrCreateSelectionDialog = this._modalService.show(
      LoginOrCreateSelectionDialogComponent,
      {
        class: "modal-dialog-centered",
        backdrop: true,
        ignoreBackdropClick: false,
      }
    );

    this.bsModalRef.hide();

    loginOrCreateSelectionDialog.content.optionSelected.subscribe(
      (option: string) => {
        const rotaAtual = this._router.url;
        const navigationExtras = {
          queryParams: {
            openedByDialogCandidatarVaga: true,
            vaga: this.data.vaga.id
          }
        };

        const isEmpresaRoute = rotaAtual === "/para-empresas" || rotaAtual === "/login-account-empresa";

        if (option === "Fazer Login") {
          this.navigateTo(isEmpresaRoute, "login-account-empresa", "login-account-candidato", navigationExtras);
        } else if (option === "Criar Conta") {
          this.navigateTo(isEmpresaRoute, "create-account-empresa", "create-account-candidato", navigationExtras);
        }
      }
    );
  }

  private navigateTo(
    isEmpresaRoute: boolean,
    empresaRoute: string,
    candidatoRoute: string,
    navigationExtras: any
  ): void {
    const route = isEmpresaRoute ? empresaRoute : candidatoRoute;
    this._router.navigate(["", route], navigationExtras);
  }

  isLoggedIn(): boolean {
    const token = this._tokenService.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  verifyLoggedIn() {
    if (this.isLoggedIn()) {
      this.dialogRef.close(true);
      if (this.appSession.getRoles().length >= 1 && this.verificaCandidato(this.appSession.user.roleNames)) {
        this.realizarCandidatura();
      } else if (this.appSession.getRoles().length >= 1 && !this.verificaCandidato(this.appSession.user.roleNames)) {
        abp.message.error('É necessário entrar com usuário candidato');
        return
      }
    } else {
      this.dialogRef.close(true);
      this.openLoginOrCreateSelectionModal();
    }
  }

  private isTokenExpired(token: string): boolean {
    const tokenPayload = this.decodeToken(token);

    if (tokenPayload && tokenPayload.exp) {
      const expirationDateSeconds = tokenPayload.exp;

      const expirationDateMilliseconds = expirationDateSeconds * 1000;

      const currentTimestampMilliseconds = new Date().getTime();

      return expirationDateMilliseconds < currentTimestampMilliseconds;
    }

    return true;
  }

  private decodeToken(token: string): any {
    try {
      const tokenPayloadBase64 = token.split(".")[1];
      const tokenPayloadJson = atob(tokenPayloadBase64);
      return JSON.parse(tokenPayloadJson);
    } catch (error) {
      return null;
    }
  }

  abrirOnboarding() {
    this._modalService.show(OnboardingModalComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        idCandidato: this.candidato.id
      }
    });
  }
}
