import { Component, HostListener, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { CandidatoDto, VagaSimplificadaDto } from '@shared/service-proxies/service-proxies';
import { ModelosTrabalho } from '@shared/constant/modelos-trabalho.model';
import { CandidatoService } from 'candidato/service/candidato.service';
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import localePtBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { AppSessionService } from '@shared/session/app-session.service';
import { CandidatarVagaEspecialComponent } from './candidatar-vaga-especial/candidatar-vaga-especial.component';
import { CharactersService } from '@shared/services/characters.service';
import { VagaService } from '@shared/services/vaga.service';

@Component({
  selector: 'app-card-vaga-especial',
  templateUrl: './card-vaga-especial.component.html',
  styleUrls: ['./card-vaga-especial.component.scss']
})
export class CardVagaEspecialComponent extends AppComponentBase implements OnInit {
  @Input() vaga: VagaSimplificadaDto;
  @Input() isInscrito: boolean;
  pcdTitle: string = this.l("Vaga para pessoas com deficiência.");
  candidato: CandidatoDto = new CandidatoDto();
  modeloHomeOffice: ModelosTrabalho = ModelosTrabalho.homeoffice;
  modeloPresencial: ModelosTrabalho = ModelosTrabalho.presencial;
  modeloHibrido: ModelosTrabalho = ModelosTrabalho.hibrido;
  descricaoTruncada = '';


  constructor(
    injector: Injector,
    private _router: Router,
    private candidatoService: CandidatoService,
    private dialog: MatDialog,
    private _sessionService: AppSessionService,
    private _charactersService: CharactersService,
    private _vagaService: VagaService,
  ) {
    super(injector);
    registerLocaleData(localePtBr);
  }
  ngOnInit(): void {
    this.truncarDescricao();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.truncarDescricao();
  }

  truncarDescricao() {
    const maxChars = this.getMaxCharacters();
    this.descricaoTruncada = this.truncateHtml(this.vaga.descricao, maxChars);
  }

  getMaxCharacters(): number {
    return this._charactersService.getMaxCharacters();
  }

  truncateHtml(html: string, maxChars: number): string {
    let charCount = 0;
    const div = document.createElement('div');
    div.innerHTML = html;

    const walkNodes = (node: Node): string => {
      if (charCount >= maxChars) return '';
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent || '';
        const remainingChars = maxChars - charCount;
        if (text.length > remainingChars) {
          charCount = maxChars;
          return text.slice(0, remainingChars) + '...';
        } else {
          charCount += text.length;
          return text;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        let result = `<${element.tagName.toLowerCase()}`;

        Array.from(element.attributes).forEach(attr => {
          result += ` ${attr.name}="${attr.value}"`;
        });

        result += '>';
        Array.from(node.childNodes).forEach(child => {
          result += walkNodes(child);
        });

        if (!['img', 'br', 'hr'].includes(element.tagName.toLowerCase())) {
          result += `</${element.tagName.toLowerCase()}>`;
        }
        return result;
      }
      return '';
    };

    return walkNodes(div);
  }

  detalhes() {
    this.candidatoService.setInscrito(this.isInscrito);
    this._router.navigate([this._sessionService.user ? 'candidato' : 'vagas', 'detalhes-vaga-especial', this.vaga.id]);
  }

  candidatar() {
    this.candidato = this.candidatoService.getCandidato();
    const vaga = this._vagaService.convertVagaSimplificadaToVagaDto(this.vaga)
    this.dialog.open(CandidatarVagaEspecialComponent, {
      data: {
        vaga: vaga,
        candidato: this.candidato,
      },
    });
  }

}
