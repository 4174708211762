import { Injectable } from '@angular/core';
import { VagaDto, VagaSimplificadaDto } from "../service-proxies/service-proxies";

@Injectable({
  providedIn: 'root'
})
export class VagaService {

  convertVagaSimplificadaToVagaDto(vaga: VagaSimplificadaDto){
    let vagaDto: VagaDto = new VagaDto();
    vagaDto.id = vaga.id;
    vagaDto.pcd = vaga.pcd;
    vagaDto.tituloVaga = vaga.cargoNome;
    vagaDto.encerrada = vaga.encerrada;
    vagaDto.urlVaga = vaga.urlVaga;
    return vagaDto;
  }
}
