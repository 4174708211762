import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import localePtBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { CidadeMicrorregiaoDto, CidadeMicrorregiaoServiceProxy, EnderecoDto } from "@shared/service-proxies/service-proxies";
import {
  ConsultaCEPResponse,
  ConsultaCepService,
} from "@shared/services/consulta-cep.service";
import { finalize, Observable, Subscription, throwError } from "rxjs";
import { JsonDataService } from "@shared/services/json-data.service";
import { DropdownSettings } from "@shared/constant/dropdown-settings.model";
import { SelectItem } from "../vitrine/filtro/select-item.interface";
import { StringUtils } from "@shared/helpers/string-utils";

@Component({
  selector: "endereco-form",
  templateUrl: "./endereco-form.component.html",
  styleUrls: ["./endereco-form.component.scss"]
})
export class EnderecoFormComponent extends AppComponentBase {
  @Input() oldEndereco: EnderecoDto;
  @Input() perfil: boolean = false;
  @Input() isCadastro: boolean = false;
  @Output() fieldChange = new EventEmitter<EnderecoDto>();

  endereco: EnderecoDto = new EnderecoDto();
  subscriptions: Subscription[] = [];
  dropdownSettings = DropdownSettings.getSettings(true);
  desabilitarCidade = true;
  listaCidadesMicrorregioes: CidadeMicrorregiaoDto[]

  //isCadastro = false;
  selectedEstados = [];
  selectedCidades = [];
  dropdownEstadosList = [];
  dropdownCidadesList = [];

  //isCadastro = true;
  selectedEstadoIsCadastro: SelectItem = this.getSelectItem();
  selectedCidadeIsCadastro: SelectItem = this.getSelectItem();
  estados: any[] = [];
  cidades: any[] = [];

  constructor(
    injector: Injector,
    private _cidadesMicrorregioesService: CidadeMicrorregiaoServiceProxy,
    private _buscarCepService: ConsultaCepService,
    private cdr: ChangeDetectorRef,
    private dataService: JsonDataService,
  ) {
    super(injector);
    registerLocaleData(localePtBr);
  }

  ngOnInit() {
    this.loadEstados();
    if (this.oldEndereco) {
      this.endereco = this.oldEndereco;
      this.setCidades();
      this.setEstados();
    }
  }

  getSelectItem(item_id: any = null, item_text: string = null): SelectItem {
    return { item_id, item_text };
  }

  setEstados() {
    this.selectedEstadoIsCadastro.item_id = this.endereco.estado;
    this.selectedEstadoIsCadastro.item_text = this.endereco.estado;

    this.selectedEstados = [
      {
        item_text: this.endereco.estado,
        item_id: "",
      },
    ];
  }
  setCidades() {
    this.selectedCidadeIsCadastro.item_text = this.endereco.cidade;
    this.selectedCidades = [
      {
        item_text: this.endereco.cidade,
        item_id: "",
      },
    ];
  }
  onCepBlur() {
    if (this.endereco && this.endereco.cep && this.endereco.cep.length < 8 && this.endereco.cep.length > 0) {
      abp.notify.warn("CEP deve ter 8 dígitos.");

      if (this.selectedCidades.length > 0 || this.selectedEstados.length > 0) {
        this.resetCidade();
        this.resetEstado();
      }
    } else if (this.endereco.cep == "") {
      this.resetCidade();
      this.resetEstado();
    } else {
      this.onSelectCep(this.endereco.cep);
    }
  }

  resetCidadeEstado() {
    this.selectedCidadeIsCadastro = this.getSelectItem();
    this.selectedEstadoIsCadastro = this.getSelectItem();
    this.cdr.markForCheck();
  }

  onSelectOrTypeEstadoCadastro(value: SelectItem) {
    const idEstadoSelecionado = this.dataService.getEstadoId(value.item_id);
    let nomeEstadoSelecionado = '';
    if (idEstadoSelecionado) {
      nomeEstadoSelecionado = this.dataService.getEstadoSigla(idEstadoSelecionado);
      this.endereco.estado = nomeEstadoSelecionado;
      this.loadCidades(nomeEstadoSelecionado);
    }
    else {
      this.endereco.estado = undefined;
    }
    this.desabilitarCidade = false;
    this.resetCidade();
  }

  onSelectOrTypeCidadeCadastro(value: SelectItem) {
    this.selectedCidadeIsCadastro = value.item_text ? value : this.getSelectItem();
    this.endereco.cidade = value.item_text ? value.item_text : undefined;
    this.endereco.microrregiaoId = this.listaCidadesMicrorregioes.find((cidadeMicrorregiao) => cidadeMicrorregiao.ibgeId == value.item_id).microrregiaoId;
    this.setCidades();
  }
  onEstadoSelected(item: any) {
    abp.ui.setBusy();
    this.endereco.estado = item['item_id'];
    this.endereco.cidade = undefined;
    this.selectedCidades = undefined;
    this.loadCidades(item['item_id']);
    this.desabilitarCidade = false;
    this.onFieldChangeEndereco();
  }

  onEstadoDeSelected() {
    this.desabilitarCidade = true;
    this.selectedCidades = undefined;
    this.selectedEstados = undefined;
    this.endereco.cidade = undefined;
    this.listaCidadesMicrorregioes = undefined;
    this.onFieldChangeEndereco();
  }

  onCidadeSelected(item: any) {
    this.endereco.cidade = item['item_text'];
    this.endereco.microrregiaoId = this.listaCidadesMicrorregioes.find((cidadeMicrorregiao) => cidadeMicrorregiao.ibgeId == item['item_id']).microrregiaoId;
    this.onFieldChangeEndereco();
  }

  onCidadeDeSelected() {
    this.endereco.cidade = undefined;
    this.endereco.microrregiaoId = undefined;
    this.onFieldChangeEndereco();
  }

  resetEstado() {
    this.selectedEstados = [];
    this.selectedEstadoIsCadastro = this.getSelectItem();
    this.endereco.estado = undefined;
    this.onFieldChangeEndereco();
    this.cdr.markForCheck();
  }

  resetCidade() {
    this.selectedCidades = [];
    this.selectedCidadeIsCadastro = this.getSelectItem();
    this.endereco.cidade = undefined;
    this.onFieldChangeEndereco();
    this.cdr.markForCheck();
  }

  onSelectCep(cep: string) {
    if (!this.endereco.cep) {
      return true;
    }
    cep = cep.replace(/\D/g, "").trim();
    const sub = this.consultarCep(cep).subscribe(
      (response) => {
        if (response.hasOwnProperty("erro")) {
          this.resetCidadeEstado();
          abp.message.error("CEP inválido");
          return false;
        } else {
          this.buscarCidadeIBGE(response.ibge);
          this.endereco.logradouro =
            this.endereco.logradouro || response.logradouro;
          this.endereco.bairro = this.endereco.bairro || response.bairro;
          this.cdr.markForCheck();
        }
      }
    );
    this.subscriptions.push(sub);
  }

  buscarCidadeIBGE(ibge) {
    this._cidadesMicrorregioesService.getCidadeIBGE(ibge)
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe((cidadeMicrorregiao: CidadeMicrorregiaoDto) => {
        this.endereco.estado = cidadeMicrorregiao.estado;
        this.endereco.microrregiaoId = cidadeMicrorregiao.microrregiaoId;
        this.endereco.cidade = cidadeMicrorregiao.cidade;

        const estadoSelect = this.estados.find((cidade: SelectItem) => cidade.item_text == cidadeMicrorregiao.estado)
        this.selectedEstadoIsCadastro = this.getSelectItem(estadoSelect['item_text'], estadoSelect['item_id']);
        this.selectedEstados = [{
          'item_text': this.dataService.getEstadoNome(cidadeMicrorregiao.estado),
          'item_id': cidadeMicrorregiao.estado,
        }]

        this.loadCidades(cidadeMicrorregiao.estado);
        this.selectedCidadeIsCadastro.item_text = cidadeMicrorregiao.cidade;
        this.selectedCidadeIsCadastro.item_id = cidadeMicrorregiao.ibgeId;

        this.selectedCidades = [{
          'item_text': cidadeMicrorregiao.cidade,
          'item_id': cidadeMicrorregiao.ibgeId,
        }]
      });
  }

  consultarCep(cep: string): Observable<ConsultaCEPResponse> {
    cep = cep.replace(/\D/g, "");
    if (cep !== " ") {
      return this._buscarCepService.consultaCEP(cep);
    } else {
      this.selectedEstadoIsCadastro = this.getSelectItem();
      this.selectedEstados = [];
      return throwError("CEP inválido");
    }
  }

  loadEstados() {
    this.dataService.getEstados().subscribe(estadosList => {
      this.estados = this.mapEstados(estadosList, 'Sigla', 'Sigla');
      this.dropdownEstadosList = this.mapEstados(estadosList, 'Sigla', 'Nome');

      if (this.oldEndereco && this.oldEndereco.estado) {
        const estadoNome = this.dataService.getEstadoNome(this.oldEndereco.estado);

        this.selectedEstados = [{
          item_text: estadoNome,
          item_id: this.oldEndereco.estado,
        }];
        this.selectedEstadoIsCadastro = { ...this.estados.find((estado: SelectItem) => estado.item_id === this.oldEndereco.estado) };
        this.loadCidades(this.oldEndereco.estado, true);
        this.cdr.markForCheck();
      }
    });
  }

  private mapEstados(estadosList: any[], idField: string, textField: string) {
    return estadosList.map((estado: any) => {
      if (estado) {
        return {
          item_id: estado[idField],
          item_text: estado[textField],
        };
      }
    });
  }

  loadCidades(estado, edit = false) {
    this._cidadesMicrorregioesService.getCidadesEstado(estado)
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe((cidadesMicrorregioes: CidadeMicrorregiaoDto[]) => {
        this.listaCidadesMicrorregioes = cidadesMicrorregioes;
        this.cidades = cidadesMicrorregioes.map((cidadeMicrorregiao: CidadeMicrorregiaoDto) => ({
          item_id: cidadeMicrorregiao.ibgeId,
          item_text: cidadeMicrorregiao.cidade,
        }));
        this.dropdownCidadesList = [...this.cidades];

        if (edit) {
          this.desabilitarCidade = false;
          const cidadeSelecionada = this.dropdownCidadesList.find((item) => item.item_text === this.endereco.cidade);
          if (cidadeSelecionada) {
            this.selectedCidades = [{
              item_text: cidadeSelecionada.item_text,
              item_id: cidadeSelecionada.item_id,
            }];
            this.selectedCidadeIsCadastro = cidadeSelecionada;
            this.desabilitarCidade = false;
          }
        }
      });
  }

  updateEndereco(): EnderecoDto {
    return this.endereco;
  }

  onFieldChangeEndereco() {
    this.fieldChange.emit();
  }

  validarCidadeEstado(): boolean {
    if (this.listaCidadesMicrorregioes.some(cidadeMicrorregiao => cidadeMicrorregiao.cidade === this.endereco.cidade)) {
      return true;
    }
    else {
      abp.notify.warn("Cidade não encontrada na lista de cidades do Estado.");
      return false;
    }
  }

  validarCidadeEstadoCep(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.endereco.cep && this.endereco.cep.length > 0) {
        const cep = this.endereco.cep.replace(/\D/g, "").trim();

        if (cep.length < 8) {
          resolve(false);
          return;
        }

        this.consultarCep(cep).subscribe(
          (response: ConsultaCEPResponse) => {
            if (response.hasOwnProperty("erro")) {
              resolve(false);
              return;
            }

            const cidadeCep = response.localidade;
            const estadoCep = response.uf;

            if (
              (this.endereco.estado && this.endereco.estado !== estadoCep) ||
              (this.endereco.cidade && this.endereco.cidade !== cidadeCep)
            ) {
              abp.notify.warn("CEP não corresponde ao estado/cidade selecionados.");
              resolve(false);
              return;
            }

            this.endereco.estado = estadoCep;
            this.endereco.cidade = cidadeCep;
            this.endereco.logradouro = this.endereco.logradouro || response.logradouro;
            this.endereco.bairro = this.endereco.bairro || response.bairro;

            this.cdr.markForCheck();
            resolve(true);
          },
          (error) => {
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  ehEnderecoValido() {
    if(this.endereco.numero) this.endereco.numero = this.endereco.numero.replace(/[^a-zA-Z0-9]/g, '');
    
    const validations = [
      { condition: StringUtils.isNullOrEmpty(this.endereco.cidade), message: "O campo cidade é obrigatório" },
      { condition: StringUtils.isNullOrEmpty(this.endereco.estado), message: "O campo estado é obrigatório" },
      { condition: StringUtils.isNullOrEmpty(this.endereco.logradouro), message: "O campo logradouro é obrigatório" },
      { condition: StringUtils.isNullOrEmpty(this.endereco.bairro), message: "O campo bairro é obrigatório" },
    ];

    for (const validation of validations) {
      if (validation.condition) {
        this.notify.warn(this.l(validation.message));
        return false;
      }
    }
    return true;
  }
}
